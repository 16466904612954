import './FooterComponent.css';
import { useNavigate } from 'react-router-dom';

function FooterComponent() {

    return (
        <footer className="footer">
            <p>2024 - DYHTG Hackathon GuitarGuitar Challenge</p>
        </footer>
    );
}

export default FooterComponent